<template>
  <div>
    <v-tabs v-model="tab_details">
      <v-tab dense :value="0">BODAS</v-tab>
      <v-tab dense :value="1">COMUNIONES</v-tab>
      <v-tab dense :value="2">CORPORATIVOS</v-tab>
      <v-tab dense :value="3">EVENTOS</v-tab>
    </v-tabs>
    <v-tabs-items
          style="font-size: 0.9rem;flex-grow: 1;"
          class="py-1"
          v-model="tab_details"
        >
          <v-tab-item :value="0">
            <ResumenBoda></ResumenBoda>
          </v-tab-item>
          <v-tab-item :value="1">
            <ResumenComunion></ResumenComunion>
          </v-tab-item>
          <v-tab-item :value="2">
            <ResumenCorporativo></ResumenCorporativo>
          </v-tab-item>
          <v-tab-item :value="3">
            <ResumenEvento></ResumenEvento>
          </v-tab-item>
        </v-tabs-items>
  </div>
</template>

<script>

export default {
  components: {
    ResumenBoda: () => import("../components/ResumenBoda.vue"),
    ResumenComunion: () => import("../components/ResumenComunion.vue"),
    ResumenCorporativo: () => import("../components/ResumenCorporativo.vue"),
    ResumenEvento: () => import("../components/ResumenEvento.vue"),
  },
  data(){
    return {
      value: 0,
      tab_details: 0,
    }
  },
};
</script>